/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* This Impoart are Based on Swipper Js*/
@import "~swiper/scss";
@import "~@ionic/angular/css/ionic-swiper";
@import "~swiper/scss";
@import "~swiper/scss/autoplay";
@import "~swiper/scss/keyboard";
@import "~swiper/scss/pagination";
@import "~swiper/scss/scrollbar";
@import "~swiper/scss/zoom";
@import "~@ionic/angular/css/ionic-swiper";

@import "~swiper/scss/effect-fade";
@import "~swiper/scss/navigation";

// Classes for Toast
.toast-custom-class {
  --color: #3b4044;
  --border-color: #e2e3e5;
  --border-radius: 10px;
  --border-style: solid;
  --border-width: 3px;
  --background: #e2e3e5;
  --button-color: #3b4044;
}

.toast-custom-class-error {
  --color: #a05d63;
  --border-color: #f8d7da;
  --border-radius: 10px;
  --border-style: solid;
  --border-width: 3px;
  --background: #f8d7da;
  --button-color: #a05d63;
}

.toast-custom-class-success {
  --color: #155724;
  --border-color: #d4edda;
  --border-radius: 10px;
  --border-style: solid;
  --border-width: 3px;
  --background: #d4edda;
  --button-color: #155724;
}

.toast-custom-class-warning {
  --color: #a08330;
  --border-color: #fff3cd;
  --border-radius: 10px;
  --border-style: solid;
  --border-width: 3px;
  --background: #fff3cd;
  --button-color: #a08330;
}

.toast-custom-class-info {
  --color: #0c5460;
  --border-color: #0c5460;
  --border-radius: 10px;
  --border-style: solid;
  --border-width: 3px;
  --background: #d1ecf1;
  --button-color: #0c5460;
}
